import { Helmet } from 'react-helmet-async';

const MetaTags = ({ title, description, image, url }) => {
  return (
    <Helmet async={false} prioritizeSeoTags={true}>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      <meta property="image:width" content="600" />
      <meta property="image:height" content="600" />
      <meta itemProp="url" content={url} />

      {/* Facebook tags */}
      <meta property="og:type" content={'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="600" />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:url" content={url} />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      {/*<meta name="twitter:creator" content={name} />}*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* End Twitter tags */}
    </Helmet>
  );
};

export default MetaTags;
