import { createSlice } from '@reduxjs/toolkit';

export const VariantsSlice = createSlice({
  name: 'Variants',
  initialState: {
    variantIndex: null,
    productId: null
  },
  reducers: {
    setVariant: (state, action) => {
        state.variantIndex = action.payload.variantIndex;
        state.productId = action.payload.productId;
    },
  },
  extraReducers: {},
});

export const { setVariant } = VariantsSlice.actions;

export default VariantsSlice.reducer;
