import { configureStore } from '@reduxjs/toolkit';
import MerchantReducer from './merchant/merchantSlice';
import ProductsReducer from './products/productsSlice';
import CategoriesReducer from './categories/categoriesSlice';
import VariantsSlice from './variants/variantsSlice';

export const store = configureStore({
  reducer: {
    merchant: MerchantReducer,
    products: ProductsReducer,
    categories: CategoriesReducer,
    variant: VariantsSlice
  },
});
