import abstractService from '../abstractService';

const getPodsUrl = ({ merchantId, page, elements, category }) =>
  category
    ? `pods/merchant?merchantId=${merchantId}&page=${page}&elements=${elements}&sortByOrder=asc&category=${category}`
    : `pods/merchant?merchantId=${merchantId}&page=${page}&elements=${elements}&sortByOrder=asc`;

export const getPodsByMerchant = ({ merchantId, page, elements, category }) => {
  return abstractService.getRequest(getPodsUrl({ merchantId, page, elements, category }));
};
