import React from 'react';
import { Route } from 'wouter';
import Store from '../pages/store/Store';
import ProductDetails from '../pages/productDetails/ProductDetails';

export const routes = {
  home: '/',
  productDetails: '/product/:id',
};

const AppRoutes = () => {
  return (
    <div>
      <Route path={routes.home} component={Store} />
      <Route path={routes.productDetails} component={ProductDetails} />
    </div>
  );
};

export default AppRoutes;
