import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isFetchingCategories, selectCategories, selectCategory } from '../../redux/categories/categoriesSelector';
import { changeCategory } from '../../redux/categories/categoriesSlice';
import { firstPage, getPodsThunk } from '../../redux/products/productsSlice';

const styles = {
  base: {
    fontWeight: 500,
    textTransform: 'capitalize',
    minHeight: '5px',
    padding: 0,
    margin: '5px',
    marginRigth: '10px',
    minWidth: '10px',
  },
  default_tab: {
    color: '#c3c3c3',
  },
  active_tab: {
    color: '#4a4a4a',
  },
};

function CategoryTabs() {
  const dispatch = useDispatch();

  const default_tab = {
    ...styles.base,
    ...styles.default_tab,
  };

  const active_tab = {
    ...styles.base,
    ...styles.active_tab,
  };

  const isFetching = useSelector(isFetchingCategories);
  const category = useSelector(selectCategory);
  const categories = useSelector(selectCategories);

  if (categories.length === 0) return <></>;

  const handleChangeCategory = async (cat) => {
    if (isFetching || cat === category) return;
    await dispatch(changeCategory(cat));
    await dispatch(firstPage());
    await dispatch(getPodsThunk());
  };

  return (
    <Tabs value={category} variant="scrollable" style={{ margin: '50px 20px 0px' }}>
      {categories.map(({ key }) => (
        <Tab
          style={key === category ? active_tab : default_tab}
          disableFocusRipple
          disableRipple
          onClick={() => handleChangeCategory(key)}
          label={key.toLowerCase()}
          value={key}
          key={key}
        />
      ))}
    </Tabs>
  );
}

export default CategoryTabs;
