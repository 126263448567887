import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import styles from './AppBar.module.css';
// import Paper from '@mui/material/Paper';
// import flag from '../assets/images/flags/es.svg';
// import arrow from '../assets/images/icons/dropdown.png';
import { useSelector } from 'react-redux';
import { selectLogoUrl, selectMerchantInfo, selectMerchantSettings } from '../redux/merchant/merchantSelector';
import theme from '../utils/theme';
import { Grid } from '@mui/material';

const AppBar = () => {
  const theme = useTheme();
  const merchantInfo = useSelector(selectMerchantInfo);
  const logoUrl = useSelector(selectLogoUrl);
  const settings = useSelector(selectMerchantSettings);

  const computedStyles = useMemo(() => {
    if (theme) {
      return {
        backgroundColor: theme.backgroundColor,
        borderBottom: '1px solid ' + theme.gray,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      };
    }
    return {};
  }, [theme]);

  if (!logoUrl && (!settings || !settings.back_action_url || !settings.back_action_url.trim() === '')) {
    return <div className={styles.divider} />;
  }

  return (
    <div className={styles.appBar} style={computedStyles}>
     <Grid maxWidth="1536px" container spacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
       <div className={styles.backContainer}>
        {settings && settings.back_action_url && settings.back_action_url !== '' && (
          <a href={settings.back_action_url} className={styles.back}>
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path fill={theme.darkGray} d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </svg>
          </a>
        )}
        {logoUrl && <img src={logoUrl} className={styles.logo} />}
        {/*{!logoUrl && merchantInfo && merchantInfo.companyName && (*/}
        {/*  <h1 className={styles.title}>{merchantInfo.companyName}</h1>*/}
        {/*)}*/}
      </div>
      </Grid>
      {/*<Paper*/}
      {/*  elevation={3}*/}
      {/*  color={'backgroundColor'}*/}
      {/*  className={styles.languageSelector}*/}
      {/*>*/}
      {/*  <img src={flag} className={styles.flag} />*/}
      {/*  <img src={arrow} className={styles.icon} />*/}
      {/*</Paper>*/}
    </div>
  );
};

export default AppBar;
