/**
 * Go to augmented reality with Chrome or Safari
 * @param product, the product object
 * @param ARNotSupportedFallback, if AR is not supported this function will be called
 */
export const goToAR = ({ product, ARNotSupportedFallback, withCustomBanner = true, arUrl = null }) => {
  const { glbFiles, glbFilesUploaded, usdzFiles, usdzFilesUploaded } = product;
  const anchor = document.getElementById('ar-link');

  //Check for android and Google Chrome compatibility
  const existingGlbFiles = glbFilesUploaded?.length ? glbFilesUploaded : glbFiles;
  if (isAndroid() && isChrome() && (arUrl || existingGlbFiles?.length)) {
    gotToARChromeAndroid({
      product,
      arUrl,
      anchor,
      existingGlbFiles,
    });
    return;
  }
  //Check for iOS and Safari compatibility
  const existingUsdzFiles = usdzFilesUploaded?.length ? usdzFilesUploaded : usdzFiles;
  if (isIOS() && (anchor.relList.supports('ar') || isChrome()) && (arUrl || existingUsdzFiles?.length)) {
    gotToARSafariChromeIOS({
      product,
      arUrl,
      anchor,
      withCustomBanner,
      existingUsdzFiles,
    });
    return;
  }

  if (ARNotSupportedFallback) ARNotSupportedFallback();
};

const gotToARChromeAndroid = ({ product, arUrl = null, anchor, existingGlbFiles }) => {
  const { title: fullTitle, externalBuyUrl, externalBuyEnabled, id } = product;

  const title = fullTitle ? `${fullTitle.slice(0, 37)}...` : null;

  let glbURL = arUrl
    ? `intent://arvr.google.com/scene-viewer/1.0?file=${arUrl}`
    : `intent://arvr.google.com/scene-viewer/1.0?file=${existingGlbFiles[existingGlbFiles.length - 1].URL}`;
  glbURL = `${glbURL}&mode=ar_only`;

  // When set to true, users will be able to place the model on a vertical surface.
  glbURL = `${glbURL}&enable_vertical_placement=true`;

  const text = `title: ${title}`;
  if (text && text.trim() !== '') {
    glbURL = `${glbURL}&title=${text.trim().replaceAll(' ', '%20')}`;
  } else {
    glbURL = `${glbURL}&title=-`;
  }

  const domainUrl = `${window.location.protocol}//${window.location.host}`;
  if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
    glbURL = `${glbURL}&link=${externalBuyUrl.trim().replaceAll(' ', '%20')}`;
  } else {
    glbURL = `${glbURL}&link=${domainUrl}/product/${id}?noAR=1`;
  }
  glbURL = `${glbURL}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${domainUrl};end;`;
  anchor.setAttribute('href', glbURL);
  anchor.click();
};

const gotToARSafariChromeIOS = ({ product, arUrl = null, anchor, withCustomBanner, existingUsdzFiles }) => {
  const { price: priceInCents, currency, title: fullTitle, externalBuyUrl, externalBuyEnabled, id } = product;

  const title = fullTitle ? `${fullTitle.slice(0, 37)}...` : null;

  let customBannerTitle = '-';
  let customBannerPrice = '-';
  let customBannerActionText = 'BUY';
  let customDomain = 'AR by yuge.nl';
  let usdzUrl = arUrl
    ? `${arUrl}#allowsContentScaling=1`
    : `${existingUsdzFiles[existingUsdzFiles.length - 1].URL}#allowsContentScaling=1`;
  const priceInCentsStr = priceInCents ? `${priceInCents}`.trim() : '';
  if (withCustomBanner) {
    if (title && title.trim() !== '') {
      const trimmedTitle = title.trim();
      customBannerTitle = trimmedTitle.replaceAll(' ', '__SPACE__');
    }
    if (priceInCentsStr && currency && currency.trim() !== '') {
      if (currency.trim() === '€') {
        customBannerPrice = `_EUR_${priceInCentsStr}`;
      } else {
        customBannerPrice = `${currency.trim()}${priceInCentsStr}`;
      }
    }

    if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
      customDomain = externalBuyUrl.split('/')[2];
    }

    const trimmedDomain = customDomain.trim();
    customDomain = trimmedDomain.replaceAll(' ', '__SPACE__');
    usdzUrl = arUrl
      ? `${arUrl}#allowsContentScaling=1&custom=https://dev-banner.goyuge.com/${customBannerTitle}/${customBannerPrice}/${customBannerActionText}/${customDomain}`
      : `${
          existingUsdzFiles[existingUsdzFiles.length - 1].URL
        }#allowsContentScaling=1&custom=https://dev-banner.goyuge.com/${customBannerTitle}/${customBannerPrice}/${customBannerActionText}/${customDomain}`;
    usdzUrl = `${usdzUrl}&customHeight=medium`; // height of the custom banner (small, medium, large)
  } else {
    if (title && title.trim() !== '') {
      const trimmedTitle = title.trim();
      usdzUrl = `${usdzUrl}&checkoutTitle=${trimmedTitle}`; // title to use in case "custom banner" url fails.
    } else {
      usdzUrl = `${usdzUrl}&checkoutTitle=-`; // title to use in case "custom banner" url fails.
    }

    if (priceInCentsStr && currency && currency.trim() !== '') {
      usdzUrl = `${usdzUrl}&price=${currency.trim()}${priceInCentsStr}`; // price and currency to use in case "custom banner" url fails.
    }

    usdzUrl = `${usdzUrl}&callToAction=${customBannerActionText}`; // Text to show for blue button in case "custom banner" url fails.
    usdzUrl = `${usdzUrl}&canonicalWebPageURL=${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`; // The action will trigger link to product page without AR
  }

  anchor.setAttribute('href', usdzUrl);
  anchor.addEventListener(
    'message',
    function (_event) {
      if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
        window.location.href = externalBuyUrl.trim().replaceAll(' ', '%20');
      } else if (typeof window !== 'undefined') {
        window.location.href = `${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`;
      }
    },
    false,
  );

  anchor.click();
};

function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function isChrome() {
  return navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || (isIOS() && /CriOS/i.test(navigator.userAgent));
}
