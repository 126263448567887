export const selectMerchantInfo = (state) => state.merchant.merchantInfo;

export const selectMerchantId = (state) => {
  if (state.merchant && state.merchant.brandingStatus && state.merchant.brandingStatus.merchant_id) {
    return state.merchant.brandingStatus.merchant_id;
  }
  return null;
};

export const selectLogoUrl = (state) => {
  if (state.merchant && state.merchant.brandingStatus && state.merchant.brandingStatus.logo) {
    return state.merchant.brandingStatus.logo.url;
  }
  return null;
};

export const selectCoverImageUrl = (state) => {
  const merchantInfo = selectMerchantInfo(state);
  if (merchantInfo && merchantInfo.images && merchantInfo.images.length > 0) {
    return merchantInfo.images[0].URL;
  }
  return null;
};

export const selectMerchantSettings = (state) => {
  if (state.merchant && state.merchant.brandingStatus && state.merchant.brandingStatus.settings) {
    return state.merchant.brandingStatus.settings;
  }
  return null;
};
