/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useRoute } from 'wouter';
import { getProductById } from '../../services/productsService';
import styles from './ProductDetails.module.css';
import { routes } from '../../routes/routes';
import { goToAR } from '../../utils/augmentedReality';
import MetaTags from '../../components/MetaTags';
import { Alert } from '@mui/material';
import { getImageUri } from '../../utils/images';
import { LIVE } from '../../services/abstractService';
import YugeLogo from '../../components/YugeLogo';
import { testURL } from '../../globals';
import init from '../../utils/easel';

const ProductDetails = () => {
  const [isMobile2, setIsMobile2] = useState(window.matchMedia('(max-width: 768px)').matches);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [_, params] = useRoute(routes.productDetails);
  const getProductTriggered = useRef(false);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [processingAssets, setProcessingAssets] = useState(false);
  const [isActive3dModel, setIsActive3dModel] = useState(false);

  const shouldGotoAR = useCallback((currentProduct) => {
    const newProduct = { ...currentProduct };
    const activeExtraction = currentProduct?.extractions?.filter((i) => i.active === true)[0];

    // If there is an active extraction then will use 2d glb and usdz
    if (activeExtraction) {
      if (!activeExtraction.filename) {
        setProcessingAssets(true);
        return;
      }
      const glbFiles = currentProduct?.glbFiles?.filter((i) => i.ext_filename === activeExtraction.filename);
      const usdzFiles = currentProduct?.usdzFiles?.filter((i) => i.ext_filename === activeExtraction.filename);

      if (!glbFiles || glbFiles.length === 0 || !usdzFiles || usdzFiles.length === 0) {
        setProcessingAssets(true);
        return;
      }

      newProduct.glbFiles = glbFiles;
      newProduct.usdzFiles = usdzFiles;
      newProduct.glbFilesUploaded = null;
      newProduct.usdzFilesUploaded = null;
    } else {
      // If there is no active extraction will try to get the active 3d models
      const activeGLBModels = currentProduct?.glbFilesUploaded?.filter((i) => {
        return i.active === true;
      });
      const activeUSDZModels = currentProduct?.usdzFilesUploaded?.filter((i) => i.active === true);

      if (/Android/i.test(navigator.userAgent)) {
        if (!activeGLBModels || activeGLBModels.length === 0) {
          setProcessingAssets(true);
          return;
        }
      } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        if (!activeUSDZModels || activeUSDZModels.length === 0) {
          setProcessingAssets(true);
          return;
        }
      }

      newProduct.glbFiles = null;
      newProduct.usdzFiles = null;
      newProduct.glbFilesUploaded = activeGLBModels;
      newProduct.usdzFilesUploaded = activeUSDZModels;
    }

    goToAR({ product: newProduct });
  }, []);

  const getProduct = useCallback(
    async (productId) => {
      try {
        setLoading(true);
        const res = await getProductById({ productId });
        if (res?.data) {
          setProduct(res.data);

          if (res.data.glbFilesUploaded?.length) {
            res.data.glbFilesUploaded.forEach((glb) => {
              if (glb.active) setIsActive3dModel(true);
            });
          }

          const urlSearchParams = new URLSearchParams(window.location.search);
          const queryParams = Object.fromEntries(urlSearchParams.entries());

          if (!queryParams?.noAR) {
            // url?noAR=true will not go to AR
            if (queryParams?.arUrl) {
              // if there is an arUrl, go directly to AR with this url ex: //https://osielprod.nl/product/{id}?arUrl={url}
              goToAR({
                product: res.data,
                arUrl: queryParams.arUrl,
              });
            } else {
              shouldGotoAR(res.data);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setShowNotFound(true);
        setLoading(false);
        console.log('Error getting product by id', error);
      }
    },
    [shouldGotoAR],
  );

  const initAnimation = (error) => {
    if (!error && imageRef.current?.complete && canvasRef.current) {
      init(canvasRef.current, imageRef.current);
    } else {
      console.log('Image extraction load failure!');
    }
  };

  useEffect(() => {
    if (!getProductTriggered.current && getProduct && params?.id && !product) {
      getProductTriggered.current = true;
      getProduct(params.id).then();
    }
  }, [getProduct, params, product]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (mq) => {
      setIsMobile2(mq.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  if (loading) {
    return <div />; //TODO: show spinner
  }

  if (processingAssets) {
    return (
      <div className={styles.centerMessage}>
        <Alert color="warning">
          There is no AR active asset compatible with your operating system. They could be in process of creation.
          Please try again in a couple of minutes.
        </Alert>
      </div>
    );
  }

  if (showNotFound) {
    return (
      <div className={styles.centerMessage}>
        <Alert color="error">Product not found.</Alert>
      </div>
    );
  }

  let qrURL = window.location.href.replace('noAR=1', '');
  if (qrURL.includes('localhost:3000')) {
    qrURL = qrURL.replace('localhost:3000', testURL);
  }

  const width = screen.height > screen.width ? screen.height / 4 : screen.width / 4;
  const height = screen.height > screen.width ? screen.height / 4 : screen.width / 4;

  const renderExtraction = () => {
    const activeExtraction = product?.extractions?.filter((i) => i.active === true)[0];
    if (!activeExtraction) return null;

    return (
      <div className={styles.variantContainer}>
        <img
          id="currentExtraction"
          ref={imageRef}
          crossOrigin="anonymous"
          onLoad={() => initAnimation(false)}
          onError={() => initAnimation(true)}
          style={{ display: 'none' }}
          className={styles.variantExt}
          src={activeExtraction.URLWebp || activeExtraction.URL}
          alt="AR Product Extraction"
        />
        <canvas id="canvas" ref={canvasRef} height={750} width={750} style={{ maxHeight: '100%' }} />
      </div>
    );
  };

  return (
    <div className={styles.productContainer2}>
      {!product && (
        <MetaTags
          title={'Product view & QR code'}
          description={'This page shows product view including QR code for Augmented Reality'}
          image={'https://merchant.goyuge.com/yuge-logo.svg'}
          url={window.location.href}
        />
      )}
      {product && (
        <MetaTags
          title={product.title || 'Product view & QR code'}
          description={product.description || 'This page shows product view including QR code for Augmented Reality'}
          image={getImageUri(product.images, false, true)}
          url={window.location.href}
        />
      )}
      <div
        style={{
          width: '100%',
          padding: '1.5rem',
          paddingTop: '2.5rem',
          paddingBottom: '2.5rem',
          display: 'flex',
          flexDirection: isMobile2 ? 'column-reverse' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '3.5rem',
        }}>
        <div
          style={{
            display: ' flex',
            width: isMobile2 ? '100%' : '50%',
            height: height,
            minHeight: '600px',
            backgroundColor: 'white',
            borderRadius: '20px',
          }}>
          {!isActive3dModel && renderExtraction()}
          {isActive3dModel && !!product && (
            <div
              className="mt-4 relative flex items-center justify-center"
              style={{ width: '100%', height: '100%', borderRadius: '20px' }}>
              <iframe
                title={'product-3d-model'}
                src={`https://3d.goyuge.com/${product.id}?noAR=1&devMode=${LIVE ? 0 : 1}`}
                width="100%"
                height="100%"
                style={{ border: 'none', borderRadius: '20px' }}
              />
            </div>
          )}
        </div>
        <div
          key={product.id}
          style={{
            width: width * 0.85,
            height: height,
          }}
          className={styles.qrContainer2}>
          <QRCode size={width / 2} value={qrURL} viewBox="0 0 256 256" bgColor="rgba(1, 1, 1, 0)" />
          <div className={styles.qrContainer}>
            <p
              style={{
                marginTop: '1.5rem',
                marginBottom: '1rem',
                fontSize: '1.25rem',
                textAlign: 'center',
                fontWeight: 600,
                lineHeight: '1.75rem',
              }}>
              Scan this QR code with your <br />
              mobile device or pad to view it with <br /> <span style={{ fontWeight: 700 }}>Augmented Reality</span>.
            </p>
          </div>
          <span className={styles.rowPowered2} style={{ lineHeight: '1.5rem', fontSize: '1rem' }}>
            Augmented Reality powered by
          </span>
          <a href={'https://goyuge.com'} className={styles.yugeLink2}>
            <YugeLogo />
          </a>
          {/*<img src={ARBadgeIcon} alt={'AR logo'} className={styles.aRIcon} />*/}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
