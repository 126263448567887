import React, { memo } from 'react';

const YugeLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 18"
      className="w-full">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#ffb000">
          <g>
            <g>
              <path d="M9.677 4.72c0 2.369-1.541 4.295-3.813 4.66v5.025H4.227V9.4C1.935 9.033.376 7.109.376 4.72V1.042h1.618V4.72c0 1.732 1.213 3.158 3.042 3.158 1.868 0 3.004-1.426 3.004-3.158V1.042h1.637V4.72zM24.833 13.058c2.253 0 3.485-1.463 3.485-3.447V1.043h1.637v8.568c0 2.967-2.08 5.027-5.122 5.027-3.062 0-5.123-2.061-5.123-5.027V1.043h1.618v8.568c0 1.984 1.271 3.447 3.505 3.447zM52.89 7.126v.693c0 4.121-2.639 6.818-6.49 6.818-3.678 0-6.643-3.121-6.643-6.973 0-3.832 2.965-6.951 6.643-6.951 2.658 0 5.143 1.502 6.182 4.449H50.85c-.828-1.771-2.465-2.869-4.449-2.869-2.772 0-5.025 2.445-5.025 5.371 0 2.947 2.253 5.393 5.025 5.393 2.793 0 4.564-1.867 4.814-4.352h-4.717v-1.58h6.392v.001zM64.214 6.955h6.258v1.521h-6.258V10.4c0 1.561.828 2.428 2.368 2.428h4.737v1.578h-4.737c-2.427 0-4.005-1.482-4.005-4.006V5.048c0-2.543 1.578-4.006 4.005-4.006h4.737v1.559h-4.737c-1.54 0-2.368.867-2.368 2.447v1.907z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(YugeLogo);
