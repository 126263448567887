import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantByDomainName, getMerchantInfo } from '../../services/merchantService';
import { getMerchantCategories } from '../categories/categoriesSlice';
import { getPodsThunk } from '../products/productsSlice';

const initialState = {
  brandingStatus: null,
  merchantInfo: null,
  status: {
    pendingGetMerchantByDomainName: false,
    errorGetMerchantByDomainName: null,
    pendingGetMerchantInfo: false,
    errorGetMerchantInfo: null,
  },
};

export const getMerchantInfoThunk = createAsyncThunk(
  'merchantSlice/GET_MERCHANT_INFO',
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await getMerchantInfo(merchantId);
      if (response.status === 200) {
        return response.data;
      }
      return rejectWithValue({ response });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Error getMerchantInfoThunk', {
          response: error.response,
          config: error.config,
        });
        return rejectWithValue({
          response: error.response,
          config: error.config,
        });
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log('Error getMerchantInfoThunk', {
          request: error.request,
          config: error.config,
        });
        return rejectWithValue({
          request: error.request,
          config: error.config,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error getMerchantInfoThunk', {
          message: error.message,
          config: error.config,
        });
        return rejectWithValue({
          message: error.message,
          config: error.config,
        });
      }
    }
  },
);

export const getMerchantByDomainNameThunk = createAsyncThunk(
  'merchantSlice/GET_MERCHANT_BY_DOMAIN_NAME',
  async (domainName, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMerchantByDomainName(domainName);
      if (response.status === 200) {
        if (response.data && response.data.merchant_id) {
          const merchantId = response.data.merchant_id;

          await dispatch(getMerchantCategories(merchantId));
          await dispatch(getMerchantInfoThunk(merchantId));
          await dispatch(getPodsThunk(merchantId));
        }
        return response.data;
      }
      return rejectWithValue({ response });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Error getMerchantByDomainNameThunk', {
          response: error.response,
          config: error.config,
        });
        return rejectWithValue({
          response: error.response,
          config: error.config,
        });
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log('Error getMerchantByDomainNameThunk', {
          request: error.request,
          config: error.config,
        });
        return rejectWithValue({
          request: error.request,
          config: error.config,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error getMerchantByDomainNameThunk', {
          message: error.message,
          config: error.config,
        });
        return rejectWithValue({
          message: error.message,
          config: error.config,
        });
      }
    }
  },
);

export const MerchantSlice = createSlice({
  name: 'Merchant',
  initialState,
  reducers: {
    setMerchant: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: {
    [getMerchantByDomainNameThunk.pending]: (state) => {
      state.status.pendingGetMerchantByDomainName = true;
      state.status.errorGetMerchantByDomainName = null;
    },
    [getMerchantByDomainNameThunk.rejected]: (state, action) => {
      state.status.pendingGetMerchantByDomainName = false;
      state.status.errorGetMerchantByDomainName = action.payload;
    },
    [getMerchantByDomainNameThunk.fulfilled]: (state, action) => {
      state.brandingStatus = action.payload;
      state.status.pendingGetMerchantByDomainName = false;
      state.status.errorGetMerchantByDomainName = null;
    },
    [getMerchantInfoThunk.pending]: (state) => {
      state.status.pendingGetMerchantInfo = true;
      state.status.errorGetMerchantInfo = null;
    },
    [getMerchantInfoThunk.rejected]: (state, action) => {
      state.status.pendingGetMerchantInfo = false;
      state.status.errorGetMerchantInfo = action.payload;
    },
    [getMerchantInfoThunk.fulfilled]: (state, action) => {
      state.merchantInfo = action.payload;
      state.status.pendingGetMerchantInfo = false;
      state.status.errorGetMerchantInfo = null;
    },
  },
});

export const { setMerchant } = MerchantSlice.actions;

export default MerchantSlice.reducer;
