/* eslint-disable no-loop-func */

const init = (canvasRef, imgRef) => {
  let stopExecution = false;
  try {
    // Stage
    const stage = new window.createjs.Stage('canvas');

    // Add transparent extraction PNG
    // Make sure to also add <img> tags to index.html so images get preloaded
    const extractionBitmap = new window.createjs.Bitmap();
    extractionBitmap.image = imgRef;

    // Parameters
    const lineColor = 'rgba(255, 255, 255, .3)';
    const baseDimention = 750; // In px
    const maxDimention = Math.max(extractionBitmap.image.width, extractionBitmap.image.height);
    const multiplier = Math.floor(maxDimention / baseDimention);
    const lineWidth = multiplier * 8;
    const particlesPerTick = 25 + multiplier * 5; // Framerate is 30ms. Every tick we display an additional number of particles from the total path. This indirectly sets the speed of the line.
    const waitTillRemove = 800; // After n milliseconds a displayed line particle will be removed again. This indirectly sets the length of the line.

    // Set canvas size of image
    canvasRef.width = extractionBitmap.image.width;
    canvasRef.height = extractionBitmap.image.height;

    stage.addChild(extractionBitmap);
    stage.update();

    // Calculate transparency map
    const transparencyTreshold = 100;
    const ctx = canvasRef.getContext('2d');
    const { width, height } = stage.getBounds();
    const imageData = ctx.getImageData(0, 0, width, height);
    const l = imageData.data.length;
    const isTransparentMap = [];
    const transparencyMap = [];
    for (let i = 0; i < l; i += 4) {
      const a = imageData.data[i + 3];
      const isTransparent = a <= transparencyTreshold;
      isTransparentMap.push(isTransparent);
    }
    for (let i = 0; i < height; i++) {
      const start = i * width;
      const row = isTransparentMap.slice(start, start + width);
      transparencyMap.push(row);
    }

    // Calculate extraction path
    const equalPixels = (p, q) => p[0] === q[0] && p[1] === q[1];
    const includesPixel = (ps, pixel) => ps.find((p) => equalPixels(p, pixel)) !== undefined;
    const isTransparent = (x, y) => transparencyMap[y][x] === true;
    const getNeighbouringPixels = (pixel) => {
      const transpositions = [
        [-1, -1],
        [0, -1],
        [1, -1],
        [1, 0],
        [1, 1],
        [0, 1],
        [-1, 1],
        [-1, 0],
      ];
      return transpositions
        .map((transposition) => {
          const x = pixel[0] + transposition[0];
          const y = pixel[1] + transposition[1];
          if (x < 0 || x >= width) return undefined;
          if (y < 0 || y >= height) return undefined;
          return [x, y, isTransparent(x, y)];
        })
        .filter((p) => p !== undefined);
    };
    const isEdgePixel = (pixel) => {
      const [x, y] = pixel;
      if (isTransparent(x, y) === false) return false;
      const neighbouringPixels = getNeighbouringPixels(pixel);
      return neighbouringPixels.map(([, , isNonTransparent]) => isNonTransparent).includes(false);
    };
    const getFirstEdgePixel = () => {
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (isEdgePixel([x, y])) return [x, y, true];
        }
      }
      return undefined;
    };
    const getNeighbouringEdgePixels = (pixel) => {
      const neighbouringPixels = getNeighbouringPixels(pixel);
      return neighbouringPixels.filter((p) => isEdgePixel(p));
    };

    const pathStart = getFirstEdgePixel();
    const path = [];
    let nextPixels = [pathStart];
    let startingPixels = [];

    while (nextPixels.length > 0) {
      if (stopExecution) {
        break;
      }
      const pixel = nextPixels.shift();
      const neighbouringEdgePixels = getNeighbouringEdgePixels(pixel);
      const uniqueNeighbouringEdgePixels = neighbouringEdgePixels.filter(
        (p) => includesPixel(path, p) === false &&
          includesPixel(nextPixels, p) === false &&
          includesPixel(startingPixels, p) === false,
      );

      // This is the start of the path. We only use the first (from left and top) neighbouring pixel.
      if (path.length === 0) {
        if (uniqueNeighbouringEdgePixels.length > 0) {
          nextPixels = [uniqueNeighbouringEdgePixels.shift()];
          startingPixels = uniqueNeighbouringEdgePixels;
        }
        // This is anywhere in the middle of the path.
      } else {
        nextPixels = nextPixels.concat(uniqueNeighbouringEdgePixels);
      }

      // Extend the path. Dropping the transparency boolean.
      path.push([pixel[0], pixel[1]]);

      // This is the end of the path. We add the excluded neighbouring pixels from the start (in reverse order).
      if (nextPixels.length === 0 && startingPixels.length > 0) {
        nextPixels = startingPixels.reverse();
        startingPixels = [];
      }
    }

    // Animation
    const animateGlowParticle = (pixel, wait) => {
      const [x, y] = pixel;
      const s = new window.createjs.Shape();
      s.graphics.beginFill(lineColor).drawCircle(x, y, lineWidth / 2);
      stage.addChildAt(s, 0);
      s.visible = false;
      window.createjs.Tween.get(s)
        .wait(wait)
        .to({ visible: true }, 0)
        .wait(waitTillRemove)
        .call(() => {
          stage.removeChild(s);
          stage.update();
        });
    };

    // Start animating
    let pathIndex = 0;
    const pathLength = path.length;
    const incrementPathIndex = () => {
      if (stopExecution) {
        return;
      }
      pathIndex++;
      pathIndex %= pathLength;
    };

    const tick = () => {
      if (stopExecution) {
        return;
      }
      for (let i = 0; i < particlesPerTick; i++) {
        incrementPathIndex();
        const pixel = path[pathIndex];
        if (document.querySelector('#currentExtraction')?.src !== imgRef?.src) {
          stopExecution = true;
          break;
        }
        animateGlowParticle(pixel, i * (window.createjs.Ticker.framerate / particlesPerTick));
      }
    };

    window.createjs.Ticker.on('tick', tick);
  } catch (e) {
    // Hide canvas and show image on error
    console.log("Error => ", e);
    imgRef.style.display = "flex";
    canvasRef.style.display = "none";
  }
};

export default init;
