import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantCategoriesService } from '../../services/categories';

export const getMerchantCategories = createAsyncThunk(
  'REQUEST_MERCHANT_CATEGORIES',
  async (merchantId, { rejectWithValue }) => {
    const quantity = 200;
    const publish = true;
    try {
      const res = await getMerchantCategoriesService(merchantId, quantity, publish);
      if (res.status === 200) {
        return res.data;
      } else return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const merchantCategoriesSlice = createSlice({
  name: 'merchantCategories',
  initialState: {
    isFetchingMerchantCategories: true,
    merchantCategories: [],
    errorFetchingMerchantCategories: false,
    category: null,
  },
  reducers: {
    changeCategory: (state, action) => {
      state.category = action.payload;
    },
    changeCategorySwip: (state, action) => {
      const dirValue = action.payload;
      let posCategory = state.merchantCategories.findIndex((item) => item.key === state.category) + dirValue;
      if (posCategory < 0) posCategory = state.merchantCategories.length - 1;
      if (posCategory >= state.merchantCategories.length) posCategory = 0;
      state.category = state.merchantCategories[posCategory].key;
    },
  },
  extraReducers: {
    [getMerchantCategories.pending]: (state) => {
      state.isFetchingMerchantCategories = true;
    },
    [getMerchantCategories.fulfilled]: (state, action) => {
      state.isFetchingMerchantCategories = false;
      state.errorFetchingMerchantCategories = false;
      const sortedCategories = [...action.payload].sort((a, b) => a.order - b.order); //.filter(obj=>obj.publish===true)
      state.merchantCategories = sortedCategories;
      state.category = sortedCategories.length ? sortedCategories[0].key : null;
    },
    [getMerchantCategories.rejected]: (state, action) => {
      state.errorFetchingMerchantCategories = action.payload;
    },
  },
});

export const { changeCategory, changeCategorySwip } = merchantCategoriesSlice.actions;
export default merchantCategoriesSlice.reducer;
