import abstractService from '../abstractService';

const getProductsPublicUrl = ({ merchantId, page, elements }) =>
  `products?fromMerchant=${merchantId}&page=${page}&elements=${elements}&withoutMerchantInfo=true&publish=true&sortByOrderAsc=true`;
const getProductByIdUrl = ({ productId }) => `products/${productId}`;
const getProductsByPodIdUrl = ({ podId, page, elements }) =>
  `products?podId=${podId}&page=${page}&elements=${elements}&withoutMerchantInfo=true&publish=true`;

export const getProducts = ({ merchantId, page, elements }) => {
  return abstractService.getRequest(getProductsPublicUrl({ merchantId, page, elements }));
};

export const getProductById = ({ productId }) => {
  return abstractService.getRequest(getProductByIdUrl({ productId }));
};

export const getProductsByPodId = ({ podId, page, elements }) => {
  return abstractService.getRequest(getProductsByPodIdUrl({ podId, page, elements }));
};
