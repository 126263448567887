import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'wouter';
import styles from './PodItem.module.css';
import ARBadgeIcon from '../assets/images/icons/AR_badge.svg';
import Carousel from 'react-material-ui-carousel';
import { useSwipeable } from 'react-swipeable';

import { getImageUri } from '../utils/images';
import { getProductsByPodId } from '../services/productsService';

const PodItem = ({ pod }) => {
  const { defaultVariant, totalVariants, id } = pod;
  const [_, setWouterLocation] = useLocation();

  const [products, setProducts] = useState([]);
  const [productIndex, setProductIndex] = useState(0);

  const swipeConfig = useSwipeable({
    onSwiped: (eventData) => swipeVariant(eventData),
    delta: { up: 1000000, down: 1000000, left: 100, rigth: 100 },
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: false,
    rotationAngle: 0,
    swipeDuration: 500,
    touchEventOptions: { passive: true },
  });

  const handleOnClickProduct = useCallback(
    async (product) => {
      const url = `/product/${product.id}`;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        window.innerWidth <= 768;
      if (isMobile) {
        return window.open(url, 'blank');
      } else {
        return setWouterLocation(url);
      }
    },
    [setWouterLocation],
  );

  const mapProduct = useCallback((product) => {
    return {
      id: product.id,
      image: {
        URL: getImageUri(product.images, false, true),
      },
      sold: product.sold,
    };
  }, []);

  const fetchPodProducts = useCallback(async () => {
    try {
      const response = await getProductsByPodId({ podId: id, page: 1, elements: 100 }); //We will only support 100 products per pod
      if (response?.data?.length) {
        setProducts(response.data.map((p) => mapProduct(p)));
      }
    } catch (error) {
      console.log('error', { error });
    }
  }, [id, mapProduct]);

  const handleOnChange = useCallback(
    (index) => {
      if (!products[index]) {
        fetchPodProducts();
      }
      setProductIndex(index);
    },
    [fetchPodProducts, products],
  );

  const swipeVariant = useCallback(
    (eventData) => {
      const { dir } = eventData;
      let dirValue = 0;
      if (dir === 'Left') dirValue = 1;
      if (dir === 'Right') dirValue = -1;

      if (dirValue === 0) return;

      const value = (productIndex + dirValue + products.length) % products.length;
      setProductIndex(value);
    },
    [productIndex, products],
  );

  useEffect(() => {
    if (pod && mapProduct) {
      const resource = mapProduct(defaultVariant);
      const emptyResources = Array(totalVariants - 1).fill(null); //null products will produce the steps indicators
      setProducts([resource, ...emptyResources]);
    }
  }, [pod, mapProduct]);

  const soldOutLabel = useMemo(() => {
    const lang = navigator.language || navigator.userLanguage;
    if (lang === 'en' || lang.includes('en-')) return 'Sold out';
    if (lang === 'es' || lang.includes('es-')) return 'Agotado';
    if (lang === 'nl' || lang.includes('nl-')) return 'Uitverkocht';
    return 'Sold out';
  }, []);

  return (
    <Box className={styles.boxItem} {...swipeConfig}>
      <Carousel
        index={productIndex}
        timeout={0}
        swipe={false}
        onChange={handleOnChange}
        autoPlay={false}
        sx={{ height: '100% !important', borderRadius: '10px', border: '2px solid #e0e0e0' }}
        navButtonsProps={{
          className: styles.navButtons,
          style: {
            opacity: totalVariants > 1 ? 1 : 0,
          },
        }}
        indicatorContainerProps={{
          className: styles.indicatorContainer,
          style: {
            display: totalVariants > 1 ? 'block' : 'none',
          },
        }}
        indicatorIconButtonProps={{
          className: styles.indicatorIcon,
        }}
        activeIndicatorIconButtonProps={{
          className: styles.activeIndicatorIcon,
        }}>
        {products?.map((product, index) => {
          return (
            <div key={index} className={styles.variantContainer}>
              {product?.image.URL && (
                <img
                  alt=""
                  className={styles.variant}
                  onClick={() => handleOnClickProduct(product)}
                  src={product.image.URL}
                />
              )}
              <div className={styles.aRIconContainer}>
                <img src={ARBadgeIcon} alt={'AR logo'} className={styles.aRIcon} />
              </div>
              {product?.sold && <div className={styles.soldOut}>{soldOutLabel}</div>}
            </div>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default PodItem;
