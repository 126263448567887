const Theme = {
  palette: {
    primary: {
      main: '#FBBF24',
    },
    secondary: {
      main: '#E1E3E8',
    },
    backgroundColor: {
      main: '#FFFFFF',
    },
  },
  backgroundColor: '#FFFFFF',
  gray: '#E5E7EB',
  darkGray: '#414B58',
  black: '#000000',
  whiteFont: '#FFFFFF',
  pink: '#D81B60',
};

export default Theme;
