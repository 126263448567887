import React, { useMemo } from 'react';
import { Breadcrumbs, Grid, Typography, Zoom } from '@mui/material';
import styles from './ProductContainer.module.css';
import PodItem from './PodItem';
import { useSelector } from 'react-redux';
import { selectIsFetchingProducts } from '../redux/products/productsSelector';

const ProductContainer = ({ pods }) => {
  const isFetching = useSelector(selectIsFetchingProducts);

  const labels = useMemo(() => {
    const lang = navigator.language || navigator.userLanguage;
    if (lang === 'en' || lang.includes('en-')) return { loading: 'Loading...' };
    if (lang === 'es' || lang.includes('es-')) return { loading: 'Cargando...' };
    if (lang === 'nl' || lang.includes('nl-')) return { loading: 'Laden...' };
    return { loading: 'Loading...' };
  }, []);

  if (isFetching && pods?.length === 0)
    return (
      <div className={styles.centered}>
        <Grid
          maxWidth="1536px"
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          style={{ marginLeft: 8 }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ margin: '20px 25px 20px' }}>
            <Typography color="#4d4d4d">{labels.loading}</Typography>
          </Breadcrumbs>
        </Grid>
      </div>
    );

  if (pods.length === 0)
    return (
      <div className={styles.centered}>
        <Grid
          maxWidth="1536px"
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          style={{ marginLeft: 8 }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ margin: '20px 25px 20px', minHeight: '80vh' }}>
            <Typography color="#4d4d4d">There are no products in this category.</Typography>
          </Breadcrumbs>
        </Grid>
      </div>
    );

  return (
    <Grid
      maxWidth="1536px"
      container
      spacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
      className={styles.container}>
      {pods &&
        pods.map((pod, index) => {
          return (
            <Zoom key={index} in={true} style={{ transitionDelay: `${index * 200}ms` }}>
              <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                <PodItem key={pod.id} pod={pod} isExtraction={false} />
              </Grid>
            </Zoom>
          );
        })}
    </Grid>
  );
};

export default ProductContainer;
