import axios from 'axios';

export const TIME_OUT = 40000; //40 seconds

export const BASE_URL = 'https://api.goyuge.com/v1/';

//export const LIVE = !(window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168'));
export const LIVE = !(
  BASE_URL.includes('dev.api.goyuge.com') ||
  BASE_URL.includes('qa.api.goyuge.com') ||
  BASE_URL.includes('homo.api.goyuge.com') ||
  BASE_URL.includes('localhost')
);

export function objToQueryString(obj) {
  if (!obj) {
    return '';
  }
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
}

class AbstractService {
  baseApiUrl = BASE_URL;

  getHeader = () => {
    const header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return header;
  };

  getRequest = (resourceUrl) => {
    const url = `${this.baseApiUrl}${resourceUrl}`;

    const config = {
      headers: this.getHeader(),
      timeout: TIME_OUT,
    };
    console.log('AXIOS GET', { url, config });
    return axios.get(url, config);
  };

  postRequest = (resourceUrl, queryData, bodyData) => {
    const postQueryData = { ...queryData };
    const postBodyData = { ...bodyData };
    const url = `${this.baseApiUrl}${resourceUrl}?${objToQueryString(postQueryData)}`;
    const config = {
      headers: this.getHeader(),
      timeout: TIME_OUT,
    };
    console.log('AXIOS POST', { url, queryData, postBodyData, config });
    return axios.post(url, postBodyData, config);
  };
}

export default new AbstractService();
