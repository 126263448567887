import abstractService from '../abstractService';

const getBrandingStatusPublicUrl = (domainName) => `branding/status/public/${domainName}`;

const getMerchantInfoPublicUrl = (merchantId) => `merchants/merchant/${merchantId}`;

export const getMerchantByDomainName = (domainName) => {
  return abstractService.getRequest(getBrandingStatusPublicUrl(domainName));
};

export const getMerchantInfo = (merchantId) => {
  return abstractService.getRequest(getMerchantInfoPublicUrl(merchantId));
};
