import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import styles from './StoreHeader.module.css';
import { selectCoverImageUrl, selectMerchantInfo } from '../redux/merchant/merchantSelector';

const StoreHeader = () => {
  const merchantInfo = useSelector(selectMerchantInfo);
  const coverImageUrl = useSelector(selectCoverImageUrl);

  let openText = 'CLOSED NOW';
  if (merchantInfo && merchantInfo.openingHours) {
    const uppercasedDay = dayjs().format('dddd').toUpperCase();
    const workDay = merchantInfo.openingHours[uppercasedDay];
    if (workDay !== null) {
      const objectSupport = require('dayjs/plugin/objectSupport');
      dayjs.extend(objectSupport);
      const timeFrom = dayjs({
        hour: workDay[0].hour,
        minute: workDay[0].minute,
      });
      const timeTo = dayjs({
        hour: workDay[1].hour,
        minute: workDay[1].minute,
      });
      const isBetween = require('dayjs/plugin/isBetween');
      dayjs.extend(isBetween);
      const isMerchantOpen = dayjs().isBetween(timeFrom, timeTo, 'hours', '[]');

      if (isMerchantOpen) {
        openText = `OPEN NOW: from ${timeFrom.format('h:mm A')} to ${timeTo.format('h:mm A')}`;
      } else {
        openText = `CLOSED: opens at ${timeFrom.format('h:mm A')}`;
      }
    }
  }

  return (
    <div className={styles.container}>
      <img
        src={
          coverImageUrl
            ? coverImageUrl
            : 'https://upload.wikimedia.org/wikipedia/commons/3/35/Neckertal_20150527-6384.jpg'
        }
        className={styles.bgImage}
      />
      {merchantInfo && (
        <div className={styles.interior}>
          <div className={styles.interiorContainer}>
            <div className={styles.storeData}>
              <h2 />
            </div>
            <div
              className={styles.storeData}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <h1 className={styles.title}>{merchantInfo.companyName}</h1>
            </div>
            <div className={styles.storeData}>
              {merchantInfo.address && (
                <address className={styles.subtitle}>
                  <span>{merchantInfo.address}</span>
                </address>
              )}
              {merchantInfo.postalCode && merchantInfo.city && (
                <address className={styles.subtitle}>
                  <span>{`${merchantInfo.postalCode} ${merchantInfo.city}`}</span>
                </address>
              )}

              <span className={styles.workingTime}>{openText}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreHeader;
